.popularPosts {
	border-bottom: 1px solid var(--color-neutral-dark-10);
	border-top: 1px solid var(--color-neutral-dark-10);
	margin-inline: auto;
	max-width: min(85rem, calc(100vw - (var(--edge-gutter) * 2) - var(--scroll-bar)));
	padding: var(--spacing-l) 0;

	@media (--bp-medium) {
		padding: var(--spacing-l-desktop) 0;
	}
}

.popularPostsTitle {
	--font-size: var(--typography-h-xs-size);
	--font-weight: var(--typography-h-xs-weight);
	--letter-spacing: var(--typography-h-xs-letter-spacing);
	--line-height: var(--typography-h-xs-line-height);
	--font-family: var(--typography-h-xs-family);
	--vertical-spacing: var(--spacing-l);

	font-family: var(--font-family);
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	letter-spacing: var(--letter-spacing);
	line-height: var(--line-height);
	margin-bottom: var(--vertical-spacing);

	@media (--bp-medium) {
		--font-size: var(--typography-h-xs-desktop-size);
		--font-weight: var(--typography-h-xs-desktop-weight);
		--letter-spacing: var(--typography-h-xs-desktop-letter-spacing);
		--line-height: var(--typography-h-xs-desktop-line-height);
		--font-family: var(--typography-h-xs-desktop-family);
		--vertical-spacing: var(--spacing-l-desktop);
	}
}

.popularPostsList {
	--columns: 1;

	counter-reset: popularPosts;
	display: grid;
	gap: var(--spacing-m);
	grid-template-columns: repeat(var(--columns), 1fr);
	list-style: none;
	padding: 0;

	@media (--bp-medium) {
		--columns: 4;
	}

	& li {
		--counter-size: var(--typography-h-2xl-size);

		counter-increment: popularPosts;
		position: relative;

		@media (--bp-medium) {
			--counter-size: var(--typography-h-2xl-desktop-size);
		}

		&::before {
			--color: var(--color-neutral-dark-20);
			--font-weight: var(--typography-h-2xl-weight);
			--letter-spacing: var(--typography-h-2xl-letter-spacing);
			--line-height: var(--typography-h-2xl-line-height);
			--font-family: var(--typography-h-2xl-family);

			@media (--bp-medium) {
				--font-weight: var(--typography-h-2xl-desktop-weight);
				--letter-spacing: var(--typography-h-2xl-desktop-letter-spacing);
				--line-height: var(--typography-h-2xl-desktop-line-height);
				--font-family: var(--typography-h-2xl-desktop-family);
			}

			color: var(--color);
			content: counter(popularPosts, decimal-leading-zero);
			font-family: var(--font-family);
			font-size: var(--counter-size);
			font-weight: var(--font-weight);
			letter-spacing: var(--letter-spacing);
			line-height: var(--line-height);
		}

		& :global(article) {
			background-color: transparent;
			margin-top: calc(var(--counter-size) / -2.75);
		}
	}
}
